import React from "react";

import Header from "./components/header";
import Footer from "./components/footer";

const Metalloramka = () => {
  return (
    <div>
      <Header />
      <main className="bg-gray-200 py-8 space-y-8">
        <div className="mx-auto max-w-6xl container p-8 bg-white space-y-4">
          <h2>Аренда металлорамок</h2>
          <div className="flex space-x-5">
            <div className="w-1/3">
              <img
                src="https://kfpartners.ru/images/view_4066507_3086631.jpg"
                alt="Аренда металлорамок"
              />
            </div>
            <div className="news-text w-2/3">
              <p>
                Предлагаем аренду металлорамок для обеспечения безопасности
                массовых мероприятий. Стоимость 10500 рублей в первые сутки и
                4000 все последующие. Стоимость длительной аренды (свыше трех
                суток) обговаривается индивидуально.
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Metalloramka;
